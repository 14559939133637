<template>
  <v-container>
    <v-row>
      <v-col>
        <Meal :mealId="mealId" :date="date" :read-only="readOnly"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Meal from '@/components/Meal';

export default {
  mounted() {

  },
  data() {
    return {
      dialog: true,
    }
  },
  components: {
    Meal,
  },
  created() {
  },
  computed: {
    readOnly() {
      return !!this.$route.meta.readOnly;
    },
    mealId() {
      return this.$route.params.id;
    },
    date() {
      return this.$route.params.date;
    },
  },
  methods: {}
}
</script>
